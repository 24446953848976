import React, {useEffect,useRef}from 'react'
import shiva from '../assets/IMAGES/om.png'
import about from '../assets/IMAGES/about.png'
import omsmall from '../assets/IMAGES/om-small.png'
import rightarrow from '../assets/IMAGES/right-arrow.png'

const About = () => {
  // animation
  const animationElements = useRef([]);

  const checkIfInView = () => {
    const windowHeight = window.innerHeight;
    const windowTopPosition = window.scrollY;
    const windowBottomPosition = windowTopPosition + windowHeight;

    animationElements.current.forEach(element => {
      const elementHeight = element.offsetHeight;
      const elementTopPosition = element.offsetTop;
      const elementBottomPosition = elementTopPosition + elementHeight;

      if (
        elementBottomPosition >= windowTopPosition &&
        elementTopPosition <= windowBottomPosition
      ) {
        element.classList.add('in-view');
      } else {
        element.classList.remove('in-view');
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', checkIfInView);
    window.addEventListener('resize', checkIfInView);
    checkIfInView(); // Initial check

    return () => {
      window.removeEventListener('scroll', checkIfInView);
      window.removeEventListener('resize', checkIfInView);
    };
  }, []);
  return (
    <>
    <section className='breakcrumb'>
      <div className='container'>
        <div className='row'>
          <div className='col-xl-12'>
          <h1 className='roboto-medium text-white'>About Amarnath Ji Yatra 2024</h1>
          <h5 className='text-white'>(Directorate Of Rural Sanitation, Department of Rural Development & Panchayati Raj ,J&K)</h5>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className='container mt-5 animation-element slide-up testimonial' ref={(el) => {
        if (el && !animationElements.current.includes(el)) {
          animationElements.current.push(el);
        }
      }}>
        <div className='row'>
        <div className='col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12'>
              <div className='row'>
                <div className='col-xl-1 col-lg-1 col-md-1 col-sm-2 col-2'>
                  <img src={shiva} className='w-100' alt="" />
                </div>
                <div className='col-xl-11 col-lg-11 col-md-11 col-sm-10 col-10'>
                  <h3 className='roboto-medium color-blue'>About Shri Amarnath Ji Yatra </h3>
                </div>
              </div>
              <p className='mt-3 roboto-regular'>Of all Hindu deities, Lord Shiva is highly revered and popular amongst the devotees. To pay obeisance to Holy Ice Lingam, the devotees undertake the arduous annual pilgrimage to the Holy Cave Yatra located in Kashmir Himalayas in the months of June-August. The Holy Yatra is managed by Rural Sanitation which was constituted by an Act of Jammu & Kashmir State Legislature in 2000. Hon’ble Lieutenant Governor, UT of J&K is the Chairman of the Yatra 2024.
                <br></br>
                <br></br>
                The Yatra is responsible for better management of the Department of Rural Development & Panchayati Raj,J&K, upgradation of facilities for holy pilgrims and matter connected there with and incidental thereto. Assisted by a Chief Executive Officer, Additional Chief Executive Officer, and distinguished Members, towards fulfilling its mandate
              </p>

              <h3 className='roboto-medium color-blue'>Role of Yatra 2024:</h3>
              <div className='rtinew'>
              <ul >
              <li> To arrange for the proper performance of worship at the Holy Yatra.</li>
              <li> To make arrangements for the safe custody of the funds, valuable and jewelry and for the preservation of the Board Fund.</li>
              <li> To undertake developmental activities concerning the areas of the Yatra and its surroundings.</li>
              <li> To make provision for the payment of suitable emoluments to the salaried staff.</li>
              <li> To make suitable arrangements for the imparting of religious instruction and general education to the pilgrims.</li>
              <li> To undertake for the benefit of worshippers and pilgrims.</li>
                   
                        <li>The construction of buildings for their accommodation.</li>
                        <li>The construction of sanitary works.</li>
                        <li>The improvement of means of communication.</li>
                   
              <li> To make provision of medical relief for worshippers and pilgrims.</li>
              <li> To do all such things as may be incidental and conducive to the efficient management, maintenance and administration of the Holy Yatra and the Board Funds and for the convenience of the pilgrims.</li>
            </ul>
            </div>
          </div>
          <div className='col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'>
            <img src={about} className='w-100' alt="" />
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default About

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Yatri from "../assets/IMAGES/Generations.png";
import { user_get_data, user_post_data } from "../admin/adminpages/ApiServices";
import { AdminLoggedData } from "../admin/adminpages/Configuration";
import Form from "react-bootstrap/Form";
import { Dropdown } from "react-bootstrap";
import HighchartsReact from "highcharts-react-official";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import {
  admin_post_data,
  admin_post_formData,
  admin_post_UpdateformData,
  admin_post_update_data,
} from "../admin/adminpages/ApiServices";

import DonutChart from "./DonutChart";
import BarChart from "./BarChart";
import AreaChart from "./AreaChart";

const DashboardWM = () => {
  // animation
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(false);

  const animationElements = useRef([]);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [maxDate, setMaxDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [checkedCampItems, setCheckedCampItems] = useState({});
  const [checkedLocationItems, setCheckedLocationItems] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenLocation, setDropdownOpenLocation] = useState(false);
  const [flag, setFlag] = useState(true);
  const [checkedItems, setCheckedItems] = useState({});
  const [wetWasteData, setWetWasteData] = useState([]);
  const [dryWasteData, setDryWasteData] = useState([]);
  const [totalWasteData, setTotalWasteData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");

  // const camp_locations = [
  //     "Nilgrat(Baltal)",
  //     "Baltal base(Baltal)",
  //     "Neelgrath To Dumail(Baltal)",
  //     "Brarimarg(Baltal)",
  //     "Railpathri(Baltal)",
  //     "Sangam Down(Baltal)",
  //     "Kalimata track(Baltal)",
  //     "Holy cave and lower holy cave(Baltal)",
  //     "Mir Bazar(Pahalgam)",
  //     "Nunwan(Pahalgam)",
  //     "Chandanwari(Pahalgam)",
  //     "Pissutop(Pahalgam)",
  //     "Zojibal(Pahalgam)",
  //     "Sheshnag(Pahalgam)",
  //     "Wavbal(Pahalgam)",
  //     "MG Top(Pahalgam)",
  //     "Poshpathri to Dardkoot(Pahalgam)",
  //     "Panchtarni(Pahalgam)"
  // ];

  const locations = ["Baltal", "Pahalgam"];
  const Baltal = [
    "Baltal Base Camp/Dumail Camp(Baltal)",
    "Nilgrath(Baltal)",
    "Brarimarg(Baltal)",
    "Railpathri(Baltal)",
    "Sangam Down(Baltal)",
    "Kalimata track(Baltal)",
    "Holy cave and lower holy cave(Baltal)",
  ]
  const Pahalgam = [
    "Mir Bazar(Pahalgam)",
    "Nunwan(Pahalgam)",
    "Chandanwari(Pahalgam)",
    "Pissutop(Pahalgam)",
    "Zojibal(Pahalgam)",
    "Sheshnag(Pahalgam)",
    "Wavbal(Pahalgam)",
    "MG Top(Pahalgam)",
    "Poshpathri to Dardkoot(Pahalgam)",
    "Panchtarni(Pahalgam)",
  ]


  const [camp_locations, setCamp_locations] = useState([
    "Baltal Base Camp/Dumail Camp(Baltal)",
    "Nilgrath(Baltal)",
    "Brarimarg(Baltal)",
    "Railpathri(Baltal)",
    "Sangam Down(Baltal)",
    "Kalimata track(Baltal)",
    "Holy cave and lower holy cave(Baltal)",
    "Mir Bazar(Pahalgam)",
    "Nunwan(Pahalgam)",
    "Chandanwari(Pahalgam)",
    "Pissutop(Pahalgam)",
    "Zojibal(Pahalgam)",
    "Sheshnag(Pahalgam)",
    "Wavbal(Pahalgam)",
    "MG Top(Pahalgam)",
    "Poshpathri to Dardkoot(Pahalgam)",
    "Panchtarni(Pahalgam)",
  ]);
  const getCampLocation = async () => {
    if (checkedLocationItems.Pahalgam  && checkedLocationItems.Baltal) {
      let data = [
        "Baltal Base Camp/Dumail Camp(Baltal)",
        "Nilgrath(Baltal)",
        "Brarimarg(Baltal)",
        "Railpathri(Baltal)",
        "Sangam Down(Baltal)",
        "Kalimata track(Baltal)",
        "Holy cave and lower holy cave(Baltal)",
        "Mir Bazar(Pahalgam)",
        "Nunwan(Pahalgam)",
        "Chandanwari(Pahalgam)",
        "Pissutop(Pahalgam)",
        "Zojibal(Pahalgam)",
        "Sheshnag(Pahalgam)",
        "Wavbal(Pahalgam)",
        "MG Top(Pahalgam)",
        "Poshpathri to Dardkoot(Pahalgam)",
        "Panchtarni(Pahalgam)",
      ];
      setCamp_locations(data);
    } else if (checkedLocationItems.Pahalgam ) {
      let data = Pahalgam;
      setCamp_locations(data);
    } else if (checkedLocationItems.Baltal) {
      console.log("fdihv");
      let data =Baltal;
      setCamp_locations(data);
    } else {
      let data = [
        "Baltal Base Camp/Dumail Camp(Baltal)",
        "Nilgrath(Baltal)",
        "Brarimarg(Baltal)",
        "Railpathri(Baltal)",
        "Sangam Down(Baltal)",
        "Kalimata track(Baltal)",
        "Holy cave and lower holy cave(Baltal)",
        "Mir Bazar(Pahalgam)",
        "Nunwan(Pahalgam)",
        "Chandanwari(Pahalgam)",
        "Pissutop(Pahalgam)",
        "Zojibal(Pahalgam)",
        "Sheshnag(Pahalgam)",
        "Wavbal(Pahalgam)",
        "MG Top(Pahalgam)",
        "Poshpathri to Dardkoot(Pahalgam)",
        "Panchtarni(Pahalgam)",
      ];
      setCamp_locations(data);
    }
  };

  // Find the first selected location
  const firstSelectedCamp = Object.keys(checkedCampItems).find(
    (location) => checkedCampItems[location]
  );
  const firstSelectedLocation = Object.keys(checkedLocationItems).find(
    (location) => checkedLocationItems[location]
  );

  // Count the remaining selected locations
  const remainingCountCamp =
    Object.keys(checkedCampItems).filter(
      (location) => checkedCampItems[location]
    ).length - 1;
  const remainingCountLocation =
    Object.keys(checkedLocationItems).filter(
      (location) => checkedLocationItems[location]
    ).length - 1;

  const checkIfInView = () => {
    const windowHeight = window.innerHeight;
    const windowTopPosition = window.scrollY;
    const windowBottomPosition = windowTopPosition + windowHeight;

    animationElements.current.forEach((element) => {
      const elementHeight = element.offsetHeight;
      const elementTopPosition = element.offsetTop;
      const elementBottomPosition = elementTopPosition + elementHeight;

      if (
        elementBottomPosition >= windowTopPosition &&
        elementTopPosition <= windowBottomPosition
      ) {
        element.classList.add("in-view");
      } else {
        element.classList.remove("in-view");
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkIfInView);
    window.addEventListener("resize", checkIfInView);
    checkIfInView(); // Initial check

    return () => {
      window.removeEventListener("scroll", checkIfInView);
      window.removeEventListener("resize", checkIfInView);
    };
  }, []);

  const [wasteManagementData, setWasteManagementData] = useState([]);
  const getAllWasteManagementData = async () => {
    try {
      setLoading(true);
      console.log("Receiving.....");
      const res = await user_get_data("/getWasteManagementData");
      if (res) {
        console.log("Waste Management Data Response ...", res);
        setWasteManagementData(res.data.results);
        setWetWasteData(res.data.results.slice(0, 3));
        setDryWasteData(res.data.results.slice(4, 7));
        setTotalWasteData(res.data.results.slice(8, 10));
      } else {
        console.log("Error occurred");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllWasteManagementData();
  }, []);

  useEffect(() => {
    console.log("Data >>>", wasteManagementData);
  }, [wasteManagementData]);

  const cardStyle = {
    cursor: "pointer",
    transition: "transform 0.3s, box-shadow 0.3s",
  };

  const cardHoverStyle = {
    transform: "translateY(-5px)",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  };

  const handleCardClick = (path) => {
    navigate(path);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setMaxDate(today);
  }, []);

  useEffect(() => {
    // Enable button if location is selected
    if (
      Object.keys(checkedLocationItems).some(
        (key) => checkedLocationItems[key]
      ) ||
      Object.keys(checkedCampItems).some((key) => checkedCampItems[key])
    ) {
      setFlag(false);
      return;
    }
    // Enable button if both fromDate and toDate are selected
    if (toDate && fromDate) {
      setFlag(false);
      return;
    }
    // Disable button otherwise
    setFlag(true);
  }, [toDate, fromDate, checkedLocationItems, checkedCampItems]);

  const toggleDropdown = () => {
    setDropdownOpen((prevDropdownOpen) => !prevDropdownOpen);
  };

  const toggleDropdownLocation = () => {
    setDropdownOpenLocation((prevDropdownOpen) => !prevDropdownOpen);
  };

  const [updatecount, setupdateCount] = useState(1);
  const handleCheckboxChange = (e, location) => {
    const isChecked = e.target.checked;
    setCheckedCampItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [location]: isChecked,
    }));
  };

  const handleCheckboxLocationChange = (e, location) => {
    const isChecked = e.target.checked;
  
    setCheckedLocationItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [location]: isChecked,
    }));
  
    if (location === "Baltal" && !isChecked) {
      console.log("Baltal Unselected");
  
      const updatedCheckedCampItems = {};
      Object.keys(checkedCampItems).forEach((item) => {
        if (Baltal.includes(item) && checkedLocationItems.Baltal) {
          updatedCheckedCampItems[item] = false;
        } else if (!Baltal.includes(item) && !checkedLocationItems.Baltal) {
          updatedCheckedCampItems[item] = false;
        } else {
          updatedCheckedCampItems[item] = checkedCampItems[item];
        }
      });
      
  
     
      setCheckedCampItems(updatedCheckedCampItems);
    }

    if (location === "Baltal" && isChecked) {
      console.log("Baltal selected");
      if(!checkedLocationItems.Pahalgam){
        const updatedCheckedCampItems = {};
        Object.keys(checkedCampItems).forEach((item) => {
          if (Pahalgam.includes(item) && checkedLocationItems.Pahalgam) {
            updatedCheckedCampItems[item] = false;
          } else if (!Baltal.includes(item) && !checkedLocationItems.Pahalgam) {
            updatedCheckedCampItems[item] = false;
          } else {
            updatedCheckedCampItems[item] = checkedCampItems[item];
          }
        });
        
    
       
        setCheckedCampItems(updatedCheckedCampItems);
      }
      

    }

    if (location === "Pahalgam" && isChecked) {
      console.log("Pahalgam selected");
  if(!checkedLocationItems.Baltal){
    const updatedCheckedCampItems = {};
      Object.keys(checkedCampItems).forEach((item) => {
        if (Baltal.includes(item) && checkedLocationItems.Baltal) {
          updatedCheckedCampItems[item] = false;
        } else if (!Pahalgam.includes(item) && !checkedLocationItems.Baltal) {
          updatedCheckedCampItems[item] = false;
        } else {
          updatedCheckedCampItems[item] = checkedCampItems[item];
        }
      });
      
  
     
      setCheckedCampItems(updatedCheckedCampItems);
  }
      
    }
  
    if (location === "Pahalgam" && !isChecked) {
      console.log("Pahalgam Unselected");
      const updatedCheckedCampItems = {};
      Object.keys(checkedCampItems).forEach((item) => {
        if (Pahalgam.includes(item) && checkedLocationItems.Pahalgam) {
          updatedCheckedCampItems[item] = false;
        } else if (!Pahalgam.includes(item) && !checkedLocationItems.Pahalgam) {
          updatedCheckedCampItems[item] = false;
        } else {
          updatedCheckedCampItems[item] = checkedCampItems[item];
        }
      });
  
      setCheckedCampItems(updatedCheckedCampItems);
    }
  
    console.log("checkedCampItems >>>", checkedCampItems);
    setupdateCount(updatecount + 1);
  };

  useEffect(() => {
    console.log(checkedItems);
    getCampLocation();
  }, [updatecount]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    console.log("handle change running");
    setSelectedValue(e.target.value);
  };

  const handleFilter = async () => {
    if((toDate == '' &&  fromDate !='') || (toDate != '' &&  fromDate =='')){
      show_error_swal("Please select from date and to date");
      return;
    }
    if (new Date(fromDate) > new Date(toDate)) {
      show_error_swal("Invalid Date Format");
      return;
    }
    const selectedLocations = Object.keys(checkedLocationItems).filter(
      (location) => checkedLocationItems[location]
    );
    const selectedCampLocations = Object.keys(checkedCampItems).filter(
      (location) => checkedCampItems[location]
    );
    const locationsString = selectedLocations.join(",");
    const campLocationsString = selectedCampLocations.join(",");

    // Function to format date from YYYY-MM-DD to DD-MM-YY
    const formatDate = (dateString) => {
      if (dateString != "") {
        const [yyyy, mm, dd] = dateString.split("-");
        return `${yyyy}-${mm}-${dd}`; // Take last two digits of year
      }
      return "";
    };

    let values = {
      camp_locations: campLocationsString,
      location: locationsString,
      from_date: formatDate(fromDate),
      to_date: formatDate(toDate),
    };

    console.log("Filter Values:", values);

    try {
      setLoading(true);
      const res = await user_post_data("/usergetWasteManagementData", values);

      console.log("API Response:", res);

      if (res && res.data.results) {
        console.log("Filtered Results:", res.data.results);
        setWasteManagementData(res.data.results);
      } else {
        console.log("No results found.");
        setWasteManagementData([]);
      }
    } catch (error) {
      console.error("Error in API call:", error);
      // Handle error state or notify user of failure
    } finally {
      setLoading(false);
    }
  };

  const removeFilter = () => {
    console.log("removing filter...");
    setFromDate("");
    setToDate("");
    setCheckedItems({});
    setCheckedLocationItems({});
    setCheckedCampItems({});
    getAllWasteManagementData();
  };
  const show_error_swal = (message) => {
    console.log("Swal is called");
    Swal.fire({
      icon: "error",
      text: message,
      confirmButtonText: "OK",
    });
  };

  const rows = [
    [0, 1],
    [2],
    [3,4, 5],
    [6,7, 8],
    [9,10],
    [11,12, 13],
  ];

  return (
    <>
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <section className="breakcrumb">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <h1 className="roboto-medium text-white">Waste Management</h1>
              <h5 className="text-white">
                (Directorate Of Rural Sanitation, Department of Rural
                Development & Panchayati Raj ,J&K)
              </h5>
            </div>
          </div>
        </div>
      </section>
    
      <section>
        <div
          className="container mt-5 animation-element slide-up testimonial"
          ref={(el) => {
            if (el && !animationElements.current.includes(el)) {
              animationElements.current.push(el);
            }
          }}

          
        >
             
          <div className="row align-items-end mb-4">
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-12 mb-3">
              <Form.Label className="roboto-medium">
                <b>From Date</b>
              </Form.Label>
              <Form.Control
                type="date"
                name="fromDate"
                max={maxDate}
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-12 mb-3">
              <Form.Label className="roboto-medium">
                <b>To Date</b>
              </Form.Label>
              <Form.Control
                type="date"
                name="toDate"
                value={toDate}
                max={maxDate}
                onChange={(e) => {
                  console.log(e.target.value);
                  setToDate(e.target.value);
                }}
              />
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-12 mb-3">
              <Form.Label className="roboto-medium">
                <b>Location</b>
              </Form.Label>
              <Dropdown
                show={dropdownOpenLocation}
                onToggle={toggleDropdownLocation}
              >
                <Dropdown.Toggle
                  variant="white"
                  id="dropdown-basic-new"
                  className="w-100"
                  onClick={toggleDropdownLocation}
                >
                  {firstSelectedLocation
                    ? `${locations.find(
                        (loc) => loc === firstSelectedLocation
                      )} + ${remainingCountLocation} more`
                    : "Select Locations"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {locations.map((location) => (
                    <Dropdown.Item key={location} as="div">
                      <Form.Check
                        type="checkbox"
                        id={`checkbox-${location}`}
                        label={location}
                        checked={checkedLocationItems[location] || false}
                        onChange={(e) =>
                          handleCheckboxLocationChange(e, location)
                        }
                      />
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
              <Form.Label className="roboto-medium">
                <b>Camp Location:</b>
              </Form.Label>
              <Dropdown show={dropdownOpen} onToggle={toggleDropdown}>
                <Dropdown.Toggle
                  variant="white"
                  id="dropdown-basic-new"
                  onClick={toggleDropdown}
                >
                  {firstSelectedCamp
                    ? `${camp_locations.find(
                        (loc) => loc === firstSelectedCamp
                      )} + ${remainingCountCamp} more`
                    : "Select Locations"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {camp_locations.map((location) => (
                    <Dropdown.Item key={location} as="div">
                      <Form.Check
                        type="checkbox"
                        id={`checkbox-${location}`}
                        label={location}
                        checked={checkedCampItems[location] || false}
                        onChange={(e) => handleCheckboxChange(e, location)}
                      />
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12 mb-3">
              <button
                type="button"
                className="text-decoration-none btn btn-blue roboto-medium me-1"
                onClick={handleFilter}
              >
                Filter
              </button>
              <div
                className="text-decoration-none btn btn-blue roboto-medium"
                onClick={removeFilter}
              >
                <i className="fas fa-sync-alt"></i>
              </div>
            </div>
          </div>
          <div class="alert alert-primary mb-3" role="alert">
          <Form.Label className="roboto-medium mb-0">
                <b>Dashboard Metrics In Tonnes</b>
              </Form.Label>
        

      </div>
          <div className="container">
      {rows.map((row, rowIndex) => (
        <div className="row" key={rowIndex}>
          {row.map((index) => {
            if (index < wasteManagementData.length) { 
              const card = wasteManagementData[index];
              return (
                <div
                  key={index}
                  // className={`col-xl-${row.length === 2 ? '6' : '4'} col-lg-${row.length === 2 ? '6' : '4'} col-md-${row.length === 2 ? '6' : '4'} col-sm-12 col-12 mb-5 px-3`}
                  className={row.length === 3 ? 'col-xl-4 col-lg-6 col-md-6 col-sm-12  mb-3 px-3' : row.length === 2 ? 'col-xl-6 col-lg-6 col-md-6 col-sm-12  mb-3 px-3' : 'col-12 mb-3 px-3'}
                  onMouseEnter={() => setHoverIndex(index)}
                  onMouseLeave={() => setHoverIndex(null)}
                  onClick={() => card.path && handleCardClick(card.path)}
                >
                  <div
                    className={`card${index + 1} card-dashboard p-3`}
                    style={
                      hoverIndex === index
                        ? { ...cardStyle, ...cardHoverStyle }
                        : cardStyle
                    }
                  >
                    <div className="row align-items-center">
                      {/* <div className={`col-xl-${row.length === 2 ? '2' : '3'} col-lg-${row.length === 2 ? '2' : '3'} col-md-${row.length === 2 ? '2' : '3'} col-sm-4 col-4`}> */}
                      <div className={row.length === 2 ? 'col-xl-2 col-lg-2 col-md-3 col-sm-4 col-4' : row.length === 3 ? 'col-xl-3 col-lg-3 col-md-3 col-sm-4 col-4' : 'col-xl-1 col-lg-1 col-md-3 col-sm-4 col-4'}>
                        <div className={`img-bg-${index + 1} img-bg`}>
                          <img
                            src={Yatri} // Ensure Yatri is correctly imported and used
                            className="w-100"
                            alt={card.label}
                          />
                        </div>
                      </div>
                      <div className="col-xl-9 col-lg-9 col-md-9 col-sm-8 col-8">
                        <h4 className="text-center text-blue roboto-medium font-20">
                          {card.label}
                        </h4>
                        <h3 className="text-center text-blue">
                          {card.count}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              return null; // Handle case where index is out of bounds
            }
          })}
        </div>
      ))}
    </div>
        </div>
      </section>
      {/* <section>
            <div>
      <label htmlFor="my-dropdown">Choose an option:</label>
      <select id="my-dropdown" value={selectedValue} onChange={handleChange}>
        <option value="">select</option>
        <option value="wet_waste">Wet Waste</option>
        <option value="dry_waste">Dry Waste</option>
        <option value="total_waste">Total Waste Collected</option>
      </select>
      
    </div>
            <div>
     { selectedValue === "wet_waste"? <DonutChart title="Wet Waste" data={wetWasteData} />
      : selectedValue === "dry_waste"?<DonutChart  title="Dry Waste" data={dryWasteData} />
      :<DonutChart  title="Total Waste" data={totalWasteData} />}
    </div>
            </section> */}

      {/* <section>
                <BarChart></BarChart>
            </section>
            <section>
                <AreaChart></AreaChart>
            </section> */}
    </>
  );
};

export default DashboardWM;

import React,{useEffect,useRef,useState} from 'react'
import Carousel from 'react-bootstrap/Carousel';
import shiva from '../assets/IMAGES/om.png'
import one from '../assets/IMAGES/d-1.jpeg'
import two from '../assets/IMAGES/d-2.jpeg'
import three from '../assets/IMAGES/d-3.jpeg'
import four from '../assets/IMAGES/d-4.jpg'
import five from '../assets/IMAGES/d-5.jpg'
import six from '../assets/IMAGES/d-6.jpg'
import newone from '../assets/IMAGES/d-1.jpeg'
import newtwo from '../assets/IMAGES/d-2.jpeg'
import newthree from '../assets/IMAGES/d-3.jpeg'
import newfour from '../assets/IMAGES/d-4.jpg'
import newfive from '../assets/IMAGES/d-4.jpg'
import manojsinha from '../assets/IMAGES/manojsinha-img.png'
import atuldullo from '../assets/IMAGES/atalduloo.png'
import AnooMalhotra from '../assets/IMAGES/AnooMalhotra.jpg'
import ShahidIqbalChoudhary from '../assets/IMAGES/ShahidIqbalChoudhary.jpg'
import Swachh from '../assets/IMAGES/swatch.png'
import Retrofi from '../assets/IMAGES/retrofit.jpg'
import Gobardhan from '../assets/IMAGES/gobardhan.jpg'
import Swachhmisssion from '../assets/IMAGES/swachbharat.png'
import twitter from '../assets/IMAGES/twitter.png'
import slider1 from '../assets/IMAGES/1 - Copy.png'
import slider2 from '../assets/IMAGES/2 - Copy.jpg'
import slider3 from '../assets/IMAGES/3 - Copy.png'
import slider4 from '../assets/IMAGES/4 - Copy.png'
import slider5 from '../assets/IMAGES/5 - Copy.png'
import slider6 from '../assets/IMAGES/6 - Copy.png'
import slider7 from '../assets/IMAGES/7 - Copy.png'
import slider8 from '../assets/IMAGES/8 - Copy.png'

import { Link } from 'react-router-dom'

const DRS = () => {
  // animation
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const animationElements = useRef([]);

  const checkIfInView = () => {
    const windowHeight = window.innerHeight;
    const windowTopPosition = window.scrollY;
    const windowBottomPosition = windowTopPosition + windowHeight;

    animationElements.current.forEach(element => {
      const elementHeight = element.offsetHeight;
      const elementTopPosition = element.offsetTop;
      const elementBottomPosition = elementTopPosition + elementHeight;

      if (
        elementBottomPosition >= windowTopPosition &&
        elementTopPosition <= windowBottomPosition
      ) {
        element.classList.add('in-view');
      } else {
        element.classList.remove('in-view');
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', checkIfInView);
    window.addEventListener('resize', checkIfInView);
    checkIfInView(); // Initial check

    return () => {
      window.removeEventListener('scroll', checkIfInView);
      window.removeEventListener('resize', checkIfInView);
    };
  }, []);
  return (
    <>
      <section className='breakcrumb'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-12'>
              <h1 className='roboto-medium text-white'>DRS(J & K)</h1>
              <h5 className='text-white'>(Directorate Of Rural Sanitation, Department of Rural Development & Panchayati Raj ,J&K)</h5>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container mt-5 animation-element slide-up testimonial' ref={(el) => {
          if (el && !animationElements.current.includes(el)) {
            animationElements.current.push(el);
          }
        }}>
          <div className='row align-items-center mb-5 justify-content-center'>
            {/* <div className='col-xl-7 col-lg-7 col-md-8 col-sm-12 col-12 '>
            <div className='row align-items-center'>
                <div className='col-xl-1 col-lg-1 col-md-1 col-sm-2 col-2'>
                  <img src={shiva} className='w-100' alt="" />
                </div>
                <div className='col-xl-11 col-lg-11 col-md-11 col-sm-10 col-10'>
                  <h3 className='roboto-medium color-blue mb-0'>Welcome To Directorate of Rural Sanitation, J&K</h3>
               
                </div>
              </div>
            </div>
            <div className='row mt-5 d-flex align-items-center'>
              <div className='col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'>
              <p className='roboto-medium'>Directorate of Rural Sanitation, J&K was established in October, 1989 under the administrative control of Department of Rural Development and Panchayati Raj with the objective for giving the emphasis on Rural sanitation in order 
                to improve the quality of life of the rural people and also to provide privacy to dignity to women under Swachh Bharat Mission 
                (Grameen).</p>

              <div className="alert alert-primary mt-4 text-center" role="alert">
              <p className='roboto-medium text-center'>For more details, kindly visit</p>
              <Link as={Link} className='roboto-medium text-center text-decoration-none' to='https://drsjk.jk.gov.in/'><b className='text-center'>https://drsjk.jk.gov.in/</b></Link>
            </div>
              </div>
              <div className='col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12'>
              <Carousel activeIndex={index} onSelect={handleSelect} fade>
              <Carousel.Item>
              <img src={one} className='w-100 height-400' />
                
              </Carousel.Item>
              <Carousel.Item>
              <img src={two} className='w-100 height-400' />
              
              </Carousel.Item>
              <Carousel.Item>
              <img src={three} className='w-100 height-400' />
                
              </Carousel.Item>
              <Carousel.Item>
              <img src={four} className='w-100 height-400' />      
              </Carousel.Item>
              <Carousel.Item>
              <img src={five} className='w-100 height-400' />      
              </Carousel.Item>
              <Carousel.Item>
              <img src={six} className='w-100 height-400' />      
              </Carousel.Item>
            </Carousel>
                </div>
            </div> */}
              <div className='row mt-3'>
              <div className='col-12'>
              <Carousel activeIndex={index} onSelect={handleSelect} fade>
              <Carousel.Item>
              <img src={one} className='w-100 height-550' />
                
              </Carousel.Item>
              <Carousel.Item>
              <img src={two} className='w-100 height-550' />
              
              </Carousel.Item>
              <Carousel.Item>
              <img src={three} className='w-100 height-550' />
                
              </Carousel.Item>
              <Carousel.Item>
              <img src={four} className='w-100 height-550' />      
              </Carousel.Item>
              <Carousel.Item>
              <img src={five} className='w-100 height-400' />      
              </Carousel.Item>
              <Carousel.Item>
              <img src={six} className='w-100 height-550' />      
              </Carousel.Item>
            </Carousel>
            <h3 className='mt-4'>Welcome To Directorate of Rural Sanitation, J&K</h3>
            <div className='divider'>

</div>
            <p className='Welcome-para pt-3'>Directorate of Rural Sanitation, J&K was established in October, 1989 under the administrative control of Department of Rural Development and Panchayati Raj with the objective for giving the emphasis on Rural sanitation in order to improve the quality of life of the rural people and also to provide privacy to dignity to women under Swachh Bharat Mission (Grameen).</p>
              </div>
              
              </div>
              <div className='row'>
              <div className='row pt-4'>
           
             <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-2'>
             <img src={manojsinha} className='w-100 height-200' />
              <b className='text-center d-flex justify-content-center mt-3'>Shri Manoj Sinha</b>
              <p className='text-center governer-name'>Hon'ble Lieutenant Governor, J&K </p>
             </div>
            
{/*   
        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-2'>
             <img src={atuldullo} className='w-100 height-200' />
              <b className='text-center d-flex justify-content-center mt-3'>Shri Manoj Sinha</b>
              <p className='text-center governer-name'>Hon'ble Lieutenant Governor, J&K </p>
             </div> */}

             <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-2'>
             <img src={atuldullo} className='w-100 height-200' />
              <b className='text-center d-flex justify-content-center mt-3'>Sh. Atal Dulloo,IAS</b>
              <p className='text-center governer-name'> Chief Secretary, J&K</p>
             </div>
     
      
        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-2'>
             <img src={ShahidIqbalChoudhary} className='w-100 height-200' />
              <b className='text-center d-flex justify-content-center mt-3'>Dr. Shahid Iqbal Choudhary,IAS,</b>
              <p className='text-center governer-name'>Secretary to the Government, Department of Rural Development & Panchayati Raj, J&K </p>
             </div>

     
        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-2'>
             <img src={AnooMalhotra} className='w-100 height-200' />
              <b className='text-center d-flex justify-content-center mt-3'>Ms. Anoo Malhotra (JKAS)</b>
              <p className='text-center governer-name'>Director General, Rural Sanitation, J&K  </p>
             </div>
      
              </div>
              </div>
              <div className='row mt-5'>
               <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-2 '>
               <img src={Swachh} className='w-100' />
               <h4 class="mt-3 swatchh-heading">SWACHH SURVEKSHAN GRAMEEN</h4>
               </div>
               <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-2 px-3'>
               <img src={Retrofi} className='w-100' />
               <h4 class="mt-3 swatchh-heading">RETROFI SINGLE PIT TO TWIN PIT</h4>
               </div>
               <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-2 px-3'>
               <img src={Gobardhan} className='w-100' />
               <h4 class="mt-3 swatchh-heading">GOBAR-DHAN – WASTE TO WEALTH</h4>
               </div>
               <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-2'>
               <img src={Swachhmisssion} className='w-100' />
               <h4 class="mt-3 swatchh-heading">SWACHH BARAT MISSON-GRAMEEN PHASE-II</h4>
               </div>
              </div>
              <div className='row backgroundlight p-5 mt-5'>
              <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 pb-4'>
                <div className='d-flex justify-content-center'>
                <h3 className='text-white text-center counter-stat'>6650</h3><span className='text-white plus-allign'>+</span>
                </div>
                <p className='text-white text-center'>Total Villages</p>
              </div>
              <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 pb-4'>
                <div className='d-flex justify-content-center'>
                <h3 className='text-white text-center counter-stat'>6650</h3><span className='text-white plus-allign'>+</span>
                </div>
                <p className='text-white text-center'>SWM Arrangement </p>
              </div>
              <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 pb-4'>
                <div className='d-flex justify-content-center'>
                <h3 className='text-white text-center counter-stat'>6650</h3><span className='text-white plus-allign'>+</span>
                </div>
                <p className='text-white text-center'>LWM Arrangement </p>
              </div>
              <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 pb-4'>
                <div className='d-flex justify-content-center'>
                <h3 className='text-white text-center counter-stat'>0</h3><span className='text-white plus-allign'>+</span>
                </div>
                <p className='text-white text-center'>Villages In Aspiring Category </p>
              </div>
              <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 pb-4'>
                <div className='d-flex justify-content-center'>
                <h3 className='text-white text-center counter-stat'>0</h3><span className='text-white plus-allign'>+</span>
                </div>
                <p className='text-white text-center'>Villages In Rising Category  </p>
              </div>
              <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 pb-4'>
                <div className='d-flex justify-content-center'>
                <h3 className='text-white text-center counter-stat'>6650</h3><span className='text-white plus-allign'>+</span>
                </div>
                <p className='text-white text-center'>Villages In Model Category  </p>
              </div>
              <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 pb-4'>
                <div className='d-flex justify-content-center'>
                <h3 className='text-white text-center counter-stat'>6650</h3><span className='text-white plus-allign'>+</span>
                </div>
                <p className='text-white text-center'>Total ODF Plus Villages Under SBM </p>
              </div>
              </div>
              <h2 className='section-heading text-center mt-5 mb-5'>Social Wall</h2>
              
              <div className='row '>
               <div className='col-xk-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3'>
             <div className='card '>
            <div>
            <div className='row justify-content-center align-items-center px-3 py-2'>
             <div className='col-8'>
             <a href='#' className='text-decoration-none font-post'>Posts from @mission_sbm</a>
             </div>
             <div className='col-4 d-flex justify-content-end'>
             <h6> <span class="badge badge-padd">Follow</span></h6>
             </div>
             </div>
            </div>
            <div className=' border-top'>
             <div className='p-5'>
             <img src={twitter} className='twitter-width' />
             <h3 className='mt-5 nothing-font'>Nothing to see here - yet</h3>
             <p>When they post, their posts will show up here.</p>
             <div className='mt-4'>
             <a class="btn btn-twitter" href="https://x.com/i/flow/login?redirect_after_login=%2Fmission_sbm" role="button">View on X</a>
             </div>
             </div>
            </div>
             </div>
               </div>
               <div className='col-xk-6 col-lg-6 col-md-12 col-sm-12 col-12'>
               <iframe width="100%" height="400" src="https://www.youtube.com/embed/udy3zJkeQi4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>
               </div>
              </div>
              <div className='grid-container-section pt-5'>
                <div className=''>
                <img src={slider1} className='w-100  ' />    
                </div>
                <div className=''>
                <img src={slider2} className='w-100  ' />    
                </div>
                <div className=''>
                <img src={slider3} className='w-100  ' />    
                </div>
                <div className=''>
                <img src={slider4} className='w-100  ' />    
                </div>
                <div className=''>
                <img src={slider5} className='w-100  ' />    
                </div>
                <div className=''>
                <img src={slider6} className='w-100  ' />    
                </div>
                <div className=''>
                <img src={slider7} className='w-100  ' />    
                </div>
                <div className=''>
                <img src={slider8} className='w-100  ' />    
                </div>
                

              </div>
            </div>
            </div>
            </section>
      
    </>
  )
}

export default DRS

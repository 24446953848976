import React, { useRef, useEffect } from 'react'
import doimg from '../assets/IMAGES/do-1.jpeg'
import doimg1 from '../assets/IMAGES/do-3.jpeg'
// import doimg from '../assets/IMAGES/do.png'
// import dontimg from '../assets/IMAGES/dont.png'
import dontimg from '../assets/IMAGES/img-1.png'
import shiva from '../assets/IMAGES/om.png'
// import Feedbackimg from '../../src/assets/IMAGES/feeback.jpg'

const DoDont = () => {
    // animation
    const animationElements = useRef([]);

    const checkIfInView = () => {
        const windowHeight = window.innerHeight;
        const windowTopPosition = window.scrollY;
        const windowBottomPosition = windowTopPosition + windowHeight;

        animationElements.current.forEach(element => {
            const elementHeight = element.offsetHeight;
            const elementTopPosition = element.offsetTop;
            const elementBottomPosition = elementTopPosition + elementHeight;

            if (
                elementBottomPosition >= windowTopPosition &&
                elementTopPosition <= windowBottomPosition
            ) {
                element.classList.add('in-view');
            } else {
                element.classList.remove('in-view');
            }
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', checkIfInView);
        window.addEventListener('resize', checkIfInView);
        checkIfInView(); // Initial check

        return () => {
            window.removeEventListener('scroll', checkIfInView);
            window.removeEventListener('resize', checkIfInView);
        };
    }, []);
    return (
        <>
            <section className='breakcrumb'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xl-12'>
                            <h1 className='roboto-medium text-white'>Do and Don'ts</h1>
                            <h5 className='text-white'>(Directorate Of Rural Sanitation, Department of Rural Development & Panchayati Raj ,J&K)</h5>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='container mt-5 animation-element slide-up testimonial' ref={(el) => {
                    if (el && !animationElements.current.includes(el)) {
                        animationElements.current.push(el);
                    }
                }}>
            <div class="alert alert-primary" role="alert"> <b>Important :  </b>
            Don't pollute the environment and surrounding areas while trekking. Yatris should not commence their journey empty stomach. Avoid drinking alcohol and caffeinated drinks and smoking during pilgrimage. Yatris should not go for shortcuts, watch out for danger zone signals and move cautiously
            </div>
                    <div className='row mt-4'>
                        <div className='col-xl-7 col-lg-6 col-md-12 col-sm-12 col-12'>
                            <div className='row d-flex align-items-center'>
                                <div className='col-xl-1 col-lg-1 col-md-1 col-sm-2 col-2'>
                                    <img src={shiva} className='w-100' alt="" />
                                </div>
                                <div className='col-xl-11 col-lg-11 col-md-11 col-sm-10 col-10'>
                                    <h3 className='roboto-medium color-blue mb-0'>Things to Remember During Amarnath Yatra</h3>
                                </div>
                            </div>
                            <div className='do mt-4'>
                            <ul className='ps-0 do-para'>
                                <li><b>Keep the Age Limit in Mind</b>
                                <br></br>
                                Due to weather conditions and the difficulty of the Amarnath yatra trek, Shri Amarnathji Shrine Board (SASB) who organized this yatra advises children below 13 years and the elderly above 75 years to not go on this yatra. Also, pregnant ladies for more than 6 months are not allowed to undertake this yatra.</li>

                                <li><b>Pack Heavy Woolen Clothes and Essentials</b>
                                <br></br>
                                Despite the yatra beginning in the monsoon months, it is quite cold there. Therefore, you must check the weather before you travel and pack accordingly. Pack jackets, sweaters, socks, muffler, warm cap, and an umbrella with you. Other things you might keep as well include a torch, waterproof trekking shoes, and a raincoat. Also, do not forget to carry moisturizer and sunscreen with you to protect your face and hands from sunburn.

                            </li>
                                <li><b>Wear Appropriate Clothing</b>
                                <br></br>
                                Choose proper clothes during Amarnath Yatra. For example, wear a salwar kameez, pants shirt, or a tracksuit. Avoid wearing a saree as it will restrict your moment and make it difficult. Also, wear proper trekking shoes and not chappals as the roads can be slippery.</li>

                                <li><b>Keep Light Energy-Boosting Snacks with you</b>
                                <br></br>Always keep a water bottle with you at all times and keep yourself hydrated. Also, carry snacks with you that will help boost your energy during the Amarnath Yatra like dry fruits, glucose biscuits, jaggery, dark chocolates, etc. Carry them in a waterproof bag to protect the food.</li>

                                <li><b>Always Stay with your Group</b>
                                <br></br>While trekking make sure to be with your group at all times. Whether walking in front or in the rear, always keep them in sight for a better journey experience.</li>
                                <li>
                                <b>Keep Information on your Group</b>
                                <br></br>Make sure to ensure that you have information about your group in case you get separated from them. Take the help of the local police to reunite with your group. Leave the base camp with your group members and in case anyone is missing, seek the immediate help of the police. Make an announcement too.</li>

                                <li><b>Carry a First-Aid Kit
                                </b>
                                <br></br>Although you will be provided with medical facilities during Amarnath Yatra, keep first-aid with you for emergency situations.

</li>
                                <li><b>Adhere to the Guidelines

                                </b>
                                <br></br>Amarnath authorities have laid some rules to be followed during the yatra. So, they must be adhered to for your safety and security.

</li>
                            </ul>
                            </div>
                     

                        </div>
                        <div className='col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'>
                            <img src={doimg} alt="" className='w-100' />
                        </div>
                    </div>
                    <div className='row align-items-center'>
                    <div className='col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'>
                        <img src={dontimg} alt="" className='w-100' />
                    </div>
                    <div className='col-xl-7 col-lg-6 col-md-12 col-sm-12 col-12'>
                            <div className='row d-flex align-items-center'>
                                <div className='col-xl-1 col-lg-1 col-md-1 col-sm-2 col-2'>
                                    <img src={shiva} className='w-100' alt="" />
                                </div>
                                <div className='col-xl-11 col-lg-11 col-md-11 col-sm-10 col-10'>
                                    <h3 className='roboto-medium color-blue mb-0'>Things to Avoid During Amarnath Yatra</h3>
                                </div>
                            </div>
                            <div className='do mt-4'>
                            <ul className='ps-0'>
                                        <li><b>Ignore the Warning Signs
                                </b>
                                <br></br>
                                Amarnath Yatra is a difficult trek. So, there are warning signs marked at various places. You must follow the rules and keep that in mind throughout the yatra.

</li>
                                        <li><b>Take Short Cuts

                                </b>
                                <br></br>
                                There are no shortcuts to reach the cave. Thus, do not attempt to do so. It could be dangerous and will separate you from your group.

</li>
                                        <li><b>Cause Pollution

</b>
<br></br>Make sure to keep your surroundings and environment clean during the journey. The use of plastics is banned in the state and is punishable under the law. Maintain the sanctity of the area.

</li>
                                        <li><b>Consume Alcohol and Non–Veg Food

</b>
<br></br>Amarnath Yatra is sacred and thus, avoids consuming any intoxicants and non-veg food. It is strictly banned. It will also make your journey difficult as it already is.</li>
                                        <li><b>Carry Oily Foods


</b>
<br></br>Avoid taking oily foods and high-carb foods during Amarnath Yatra. Try to consume protein-rich foods like dry fruits, chana, and other nuts.

</li>
                                        
                                    </ul>
                            </div>
                     

                    </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-xl-7 col-lg-6 col-md-12 col-sm-12 col-12'>
                            <div className='row d-flex align-items-center'>
                                <div className='col-xl-1 col-lg-1 col-md-1 col-sm-2 col-2'>
                                    <img src={shiva} className='w-100' alt="" />
                                </div>
                                <div className='col-xl-11 col-lg-11 col-md-11 col-sm-10 col-10'>
                                    <h3 className='roboto-medium color-blue mb-0'>Useful Tips for Amarnath Yatra</h3>
                                </div>
                            </div>
                            <div className='do mt-4'>
                            <ul className='ps-0'>
                                <li><b>Food Supplies</b>
                                <br></br>
                                There are Government established depots on Amarnath Yatra routes at Chandanwari, Sheshnag, Panchtarni, etc. from where you can take ration at reasonable prices. Food items like biscuits, tinned foods, and more are provided for your Amarnath Temple journey as well. Langar, tea stalls, and small restaurants are also set up along the way. Along with this, firewood is also provided at Chandanwari, Sheshnag, and Panchtarni.</li>

                                <li><b>Accommodation</b>
                                <br></br>
                                Camps and huts are provided by the Government and private operators on this yatra that provides a comfortable mattress, pillows, quilts, etc.



                            </li>
                                <li><b>Insurance</b>
                                <br></br>
                                Yatris who register for Amarnath Yatra by Shri Amarnathji Shrine Board with a valid Yatra Permit (YP) are eligible for insurance of INR 3 lakhs in case of death by accident during the journey.

</li>

                                <li><b>Registration for Amarnath Yatra
                                </b>
                                <br></br>Get your registration done at least a month before the commencement of the journey. This will ensure you will have plenty of time to prepare physically and mentally for the yatra and also collect your required documents.

</li>

                                <li><b>Transportation</b>
                                <br></br>Jammu and Kashmir State Road Transport Corporation provides transport facilities during the yatra. But for a hassle-free journey, book Amarnath tour packages by Tourism of India that will include transfers, meals, sightseeing, and accommodation. 

</li>
                                <li>
                                <b>Langar</b>
                                <br></br>Langar (free food) can be found at several places on both Amarnath Yatra routes (Pahalgam and Baltal). On the Pahalgam route, langar is provided at Nunwan, Chandhanwari, Pissu top, Zojibal, Nagakoti, Sheshnag, Wavbl, Poshpatri, Kelnar, and Panchtarni. And on the Baltal route, at Baltal camp, Domail, Sangam, Brarimarg, Railpathri, and Holy Amarnath Cave.

</li>

                                <li><b>Telecommunication
                                </b>
                                <br></br>Prepaid connections don’t work in J & K. So, during the yatra, you will be given pre-loaded yatra SIMs by BSNL. It will cost you around INR 230 with 20,000s free talk time and 1.5 data for 10 data. To get this SIM card, you have to show a valid ID, a photograph, an address proof, and a copy of your registration slip given by SASB.



</li>
                                <li><b>Timings of the Yatra


                                </b>
                                <br></br>Depending upon the weather conditions, the timings of the yatra are subject to change.
                                <br></br>

                                Hope you find these Amarnath Yatra tips helpful. Keep them in mind during the yatra for your safety and security. Respect mother nature and do not litter and keep your environment clean. Savor healthy meals at various langar points and sleep in camps or huts. Keep your health in check and if you feel slightly uneasy, seek immediate medical help before you continue with your spiritual journey to Amarnath.



</li>
                            </ul>
                            </div>
                     

                        </div>
                        <div className='col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'>
                            <img src={doimg1} alt="" className='w-100' />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default DoDont

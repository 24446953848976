import React, { useState, useRef, useEffect } from "react";
import { user_post_data } from "../admin/adminpages/ApiServices"; // Assuming this is where your API service is defined

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";

const Feedback = () => {
  const animationElements = useRef([]);
 const[loading,setLoading]=useState(false);

  const checkIfInView = () => {
    const windowHeight = window.innerHeight;
    const windowTopPosition = window.scrollY;
    const windowBottomPosition = windowTopPosition + windowHeight;

    animationElements.current.forEach((element) => {
      const elementHeight = element.offsetHeight;
      const elementTopPosition = element.offsetTop;
      const elementBottomPosition = elementTopPosition + elementHeight;

      if (
        elementBottomPosition >= windowTopPosition &&
        elementTopPosition <= windowBottomPosition
      ) {
        element.classList.add("in-view");
      } else {
        element.classList.remove("in-view");
      }
    });
  };

  const show_success_swal = (message) => {
    console.log("Swal is called");
    Swal.fire({
      icon: "success",
      // title: "Success",
      text: message,
      confirmButtonText: "OK",
    });
  };
  const show_Error_swal = (message) => {
    console.log("Swal is called");
    Swal.fire({
      icon: "Errpr",
      // title: "Success",
      text: message,
      confirmButtonText: "OK",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkIfInView);
    window.addEventListener("resize", checkIfInView);
    checkIfInView(); // Initial check

    return () => {
      window.removeEventListener("scroll", checkIfInView);
      window.removeEventListener("resize", checkIfInView);
    };
  }, []);

  const initialFormData = {
    overall_experience: "",
    cleanliness_pathways: "",
    cleanliness_campsites: "",
    cleanliness_restrooms: "",
    cleanliness_food_areas: "",
    waste_management: "",
    cleanliness_water_stations: "",
    cleaning_staff_availability: "",
    additional_comments: "",
    is_website: true,
  };

  const [formData, setFormData] = useState(initialFormData);

  const postFeedbackForm = async (values) => {
    console.log('values>>', values);
    try {
      const res = await user_post_data('/web_yatri_feedback', values);
      return res;
    } catch (error) {
      console.error('Error posting feedback:', error);
      throw error;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const isFormEmpty = () => {
        // Get a copy of formData without the is_website field
    const { is_website, ...otherFields } = formData;

    // Check if every value in the otherFields object is empty or undefined
    return Object.values(otherFields).every(value => value === '' || value === undefined);

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isFormEmpty()) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please Select Atleast One',
      });
      return;
    }

    try {
      setLoading(true);
      const res = await postFeedbackForm(formData);
      if (res) {
        Swal.fire({
          icon: 'success',
          title: 'Feedback Submitted!',
          text: res.data.message,
        });
        setFormData(initialFormData);
      }
    } catch (error) {
      console.error('Error posting feedback:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An error occurred while submitting your feedback. Please try again.',
      });
    } finally {
      setLoading(false);
    }
  };

  const questions = [
    "How would you rate your overall experience of the Shri Amarnath Ji Yatra 2024?",
    "How would you rate the cleanliness of the pathways during the Yatra?",
    "How would you rate the cleanliness of the campsites?",
    "How would you rate the cleanliness of the restrooms provided during the Yatra?",
    "How would you rate the cleanliness of the areas where food was provided?",
    "How would you rate the waste management and garbage disposal facilities?",
    "How would you rate the cleanliness of the drinking water stations?",
    "How would you rate the availability and efficiency of the cleaning staff?",
    "Any Other Comments or Suggestions on Cleanliness",
  ];

  const headings = [
    "1. Overall Experience",
    "2. Cleanliness of Pathways",
    "3. Cleanliness of Campsites",
    "4. Cleanliness of Restrooms",
    "5. Cleanliness of Food Areas",
    "6. Waste Management",
    "7. Cleanliness of Drinking Water Stations",
    "8. Availability of Cleaning Staff",
    "9. Any Other Comments or Suggestions on Cleanliness",
  ];

  return (

    <div>
         <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading} // Control the visibility of the loader
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="breakcrumb">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <h1 className="roboto-medium text-white">Feedback</h1>
              <h5 className="text-white">
                (Directorate Of Rural Sanitation, Department of Rural
                Development & Panchayati Raj ,J&K)
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div
          className="container mt-5 animation-element slide-up testimonial"
          ref={(el) => {
            if (el && !animationElements.current.includes(el)) {
              animationElements.current.push(el);
            }
          }}
        >
          <p class="mb-5">
            <b>
              We appreciate your feedback on the cleanliness aspects of the Shri
              Amarnath Ji Yatra 2024. Please take a few moments to fill out this
              form. Your feedback is valuable to us and will help improve the
              pilgrimage experience for future yatris.
            </b>
          </p>

        
        
          <form onSubmit={handleSubmit}>
    
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-4">
                <Form.Label className="roboto-medium">
                  <b>{headings[0]}</b>
                </Form.Label>
                <br></br>
                <p className="roboto-regular">{questions[0]}</p>
                <label>
                  <input
                    type="radio"
                    name="overall_experience"
                    value="Excellent"
                    checked={formData.overall_experience === "Excellent"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Excellent
                </label>
                <label className="ms-2">
                  <input
                    type="radio"
                    name="overall_experience"
                    value="Good"
                    checked={formData.overall_experience === "Good"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Good
                </label>
                <label className="ms-2">
                  <input
                    type="radio"
                    name="overall_experience"
                    value="Fair"
                    checked={formData.overall_experience === "Fair"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Fair
                </label>
                <label className="ms-2">
                  <input
                    type="radio"
                    name="overall_experience"
                    value="Poor"
                    checked={formData.overall_experience === "Poor"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Poor
                </label>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-4">
                <Form.Label className="roboto-medium">
                  <b>{headings[1]}</b>
                </Form.Label>
                <br></br>
                <p className="roboto-regular">{questions[1]}</p>
                <label>
                  <input
                    type="radio"
                    name="cleanliness_pathways"
                    value="Excellent"
                    checked={formData.cleanliness_pathways === "Excellent"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Excellent
                </label>
                <label className="ms-2">
                  <input
                    type="radio"
                    name="cleanliness_pathways"
                    value="Good"
                    checked={formData.cleanliness_pathways === "Good"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Good
                </label>
                <label className="ms-2">
                  <input
                    type="radio"
                    name="cleanliness_pathways"
                    value="Fair"
                    checked={formData.cleanliness_pathways === "Fair"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Fair
                </label>
                <label className="ms-2">
                  <input
                    type="radio"
                    name="cleanliness_pathways"
                    value="Poor"
                    checked={formData.cleanliness_pathways === "Poor"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Poor
                </label>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-4">
                <Form.Label className="roboto-medium">
                  <b>{headings[2]}</b>
                </Form.Label>
                <br></br>
                <p className="roboto-regular">{questions[2]}</p>
                <label>
                  <input
                    type="radio"
                    name="cleanliness_campsites"
                    value="Excellent"
                    checked={formData.cleanliness_campsites === "Excellent"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Excellent
                </label>
                <label className="ms-2">
                  <input
                    type="radio"
                    name="cleanliness_campsites"
                    value="Good"
                    checked={formData.cleanliness_campsites === "Good"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Good
                </label>
                <label className="ms-2">
                  <input
                    type="radio"
                    name="cleanliness_campsites"
                    value="Fair"
                    checked={formData.cleanliness_campsites === "Fair"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Fair
                </label>
                <label className="ms-2">
                  <input
                    type="radio"
                    name="cleanliness_campsites"
                    value="Poor"
                    checked={formData.cleanliness_campsites === "Poor"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Poor
                </label>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-4">
                <Form.Label className="roboto-medium">
                  <b>{headings[3]}</b>
                </Form.Label>
                <br></br>
                <p className="roboto-regular">{questions[3]}</p>
                <label>
                  <input
                    type="radio"
                    name="cleanliness_restrooms"
                    value="Excellent"
                    checked={formData.cleanliness_restrooms === "Excellent"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Excellent
                </label>
                <label className="ms-2">
                  <input
                    type="radio"
                    name="cleanliness_restrooms"
                    value="Good"
                    checked={formData.cleanliness_restrooms === "Good"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Good
                </label>
                <label className="ms-2">
                  <input
                    type="radio"
                    name="cleanliness_restrooms"
                    value="Fair"
                    checked={formData.cleanliness_restrooms === "Fair"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Fair
                </label>
                <label className="ms-2">
                  <input
                    type="radio"
                    name="cleanliness_restrooms"
                    value="Poor"
                    checked={formData.cleanliness_restrooms === "Poor"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Poor
                </label>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-4">
                <Form.Label className="roboto-medium">
                  <b>{headings[4]}</b>
                </Form.Label>
                <br></br>
                <p className="roboto-regular">{questions[4]}</p>
                <label>
                  <input
                    type="radio"
                    name="cleanliness_food_areas"
                    value="Excellent"
                    checked={formData.cleanliness_food_areas === "Excellent"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Excellent
                </label>
                <label className="ms-2">
                  <input
                    type="radio"
                    name="cleanliness_food_areas"
                    value="Good"
                    checked={formData.cleanliness_food_areas === "Good"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Good
                </label>
                <label className="ms-2">
                  <input
                    type="radio"
                    name="cleanliness_food_areas"
                    value="Fair"
                    checked={formData.cleanliness_food_areas === "Fair"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Fair
                </label>
                <label className="ms-2">
                  <input
                    type="radio"
                    name="cleanliness_food_areas"
                    value="Poor"
                    checked={formData.cleanliness_food_areas === "Poor"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Poor
                </label>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-4">
                <Form.Label className="roboto-medium">
                  <b>{headings[5]}</b>
                </Form.Label>
                <br></br>
                <p className="roboto-regular">{questions[5]}</p>
                <label>
                  <input
                    type="radio"
                    name="waste_management"
                    value="Excellent"
                    checked={formData.waste_management === "Excellent"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Excellent
                </label>
                <label className="ms-2">
                  <input
                    type="radio"
                    name="waste_management"
                    value="Good"
                    checked={formData.waste_management === "Good"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Good
                </label>
                <label className="ms-2">
                  <input
                    type="radio"
                    name="waste_management"
                    value="Fair"
                    checked={formData.waste_management === "Fair"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Fair
                </label>
                <label className="ms-2">
                  <input
                    type="radio"
                    name="waste_management"
                    value="Poor"
                    checked={formData.waste_management === "Poor"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Poor
                </label>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-4">
                <Form.Label className="roboto-medium">
                  <b>{headings[6]}</b>
                </Form.Label>
                <br></br>
                <p className="roboto-regular">{questions[6]}</p>
                <label>
                  <input
                    type="radio"
                    name="cleanliness_water_stations"
                    value="Excellent"
                    checked={
                      formData.cleanliness_water_stations === "Excellent"
                    }
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Excellent
                </label>
                <label className="ms-2">
                  <input
                    type="radio"
                    name="cleanliness_water_stations"
                    value="Good"
                    checked={formData.cleanliness_water_stations === "Good"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Good
                </label>
                <label className="ms-2">
                  <input
                    type="radio"
                    name="cleanliness_water_stations"
                    value="Fair"
                    checked={formData.cleanliness_water_stations === "Fair"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Fair
                </label>
                <label className="ms-2">
                  <input
                    type="radio"
                    name="cleanliness_water_stations"
                    value="Poor"
                    checked={formData.cleanliness_water_stations === "Poor"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Poor
                </label>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-4">
                <Form.Label className="roboto-medium">
                  <b>{headings[7]}</b>
                </Form.Label>
                <br></br>
                <p className="roboto-regular">{questions[7]}</p>
                <label>
                  <input
                    type="radio"
                    name="cleaning_staff_availability"
                    value="Excellent"
                    checked={
                      formData.cleaning_staff_availability === "Excellent"
                    }
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Excellent
                </label>
                <label className="ms-2">
                  <input
                    type="radio"
                    name="cleaning_staff_availability"
                    value="Good"
                    checked={formData.cleaning_staff_availability === "Good"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Good
                </label>
                <label className="ms-2">
                  <input
                    type="radio"
                    name="cleaning_staff_availability"
                    value="Fair"
                    checked={formData.cleaning_staff_availability === "Fair"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Fair
                </label>
                <label className="ms-2">
                  <input
                    type="radio"
                    name="cleaning_staff_availability"
                    value="Poor"
                    checked={formData.cleaning_staff_availability === "Poor"}
                    onChange={handleChange}
                    className=" me-2"
                  />
                  Poor
                </label>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-4">
                <Form.Label className="roboto-medium">
                  <b>{headings[8]}</b>
                </Form.Label>
                <br></br>
                <p className="roboto-regular">{questions[8]}</p>

                <textarea
                
                  name="additional_comments"
                  value={formData.additional_comments}
                  onChange={handleChange}
                  className="form-control w-100"
                />
              </div>
              <div className="row">
                <div className="mb-3 d-flex justify-content-center">
                  <button
                    type="submit"
                    className=" btn-blue roboto-medium btn btn-blue"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>


    
    </div>
  );
};

export default Feedback;

import React from 'react'
import coming from '../assets/IMAGES/coming.jpg'

const Coming = () => {
  return (
    <>
  
     <div class="container pt-5 bg-white box-shadow-bg animation-element slide-up testimonial in-view">
         <div class="row d-flex justify-content-center pt-3">
            <h1 class="text-center">Coming Soon</h1>
               <h4 class="text-center">We're working hard to give you the best experience!</h4>
               <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <img src={coming} alt="" class="w-100" />
               </div>
            </div>
    </div>
    </>
  )
}

export default Coming

import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import FrontLayout from '../FrontLayout/FrontLayout'
import Home from '../component/Home'
import About from '../component/About'
import Contact from '../component/Contact'
import DRS from '../component/DRS'
import DoDont from '../component/DoDont'
import Feedback from '../component/Feedback'
import Gallery from '../component/Gallery'
import IEC from '../component/IEC'
// import JK from '../component/Jk'
import Maps from '../component/Maps'
import Yatra from '../component/Yatra'
import Coming from '../component/Coming'
import Videos from '../component/Videos'
import DashboardWM from '../component/DashboardWM'
// import Feedbacknew from '../component/Feedbacknew'


const Frontroutes = () => {
  return (
    <>
      <Routes>
        <Route element={<FrontLayout />}>
          <Route index element={
            <Suspense fallback={'Loading'}>
              <Home />
            </Suspense>
          } />
          <Route path='/home' element={
            <Suspense fallback={'Loading'}>
               <Home />
            </Suspense>
          } />
          <Route path='/about' element={
            <Suspense fallback={'Loading'}>
               <About />
            </Suspense>
          } />
          <Route path='/aboutUs' element={
            <Suspense fallback={'Loading'}>
               <Contact />
            </Suspense>
          } />
          <Route path='/DRS' element={
            <Suspense fallback={'Loading'}>
               <DRS />
            </Suspense>
          } />
          <Route path='/DoDont' element={
            <Suspense fallback={'Loading'}>
               <DoDont />
            </Suspense>
          } />
          <Route path='/Feedback' element={
            <Suspense fallback={'Loading'}>
               <Feedback />
            </Suspense>
          } />
          <Route path='/Gallery' element={
            <Suspense fallback={'Loading'}>
               <Gallery />
            </Suspense>
          } />
          <Route path='/IEC' element={
            <Suspense fallback={'Loading'}>
               <IEC />
            </Suspense>
          } />
          {/* <Route path='/JK' element={
            <Suspense fallback={'Loading'}>
               <JK />
            </Suspense>
          } /> */}
          <Route path='/Yatra' element={
            <Suspense fallback={'Loading'}>
               <Yatra />
            </Suspense>
          } />
          <Route path='/Maps' element={
            <Suspense fallback={'Loading'}>
               <Maps />
            </Suspense>
          } />
          <Route path='/Contact' element={
            <Suspense fallback={'Loading'}>
               <Contact />
            </Suspense>
          } />
          <Route path='/Coming' element={
            <Suspense fallback={'Loading'}>
               <Coming />
            </Suspense>
          } />
          <Route path='/Videos' element={
            <Suspense fallback={'Loading'}>
               <Videos />
            </Suspense>
          } />
          <Route path='/DashboardWM' element={
            <Suspense fallback={'Loading'}>
               <DashboardWM />
            </Suspense>
          } />
          {/* <Route path='/Feedbacknew' element={
            <Suspense fallback={'Loading'}>
               <Feedbacknew />
            </Suspense>
          } /> */}
         
        </Route>
      </Routes>
    </>
  )
}

export default Frontroutes

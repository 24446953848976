import React, { useEffect, useRef } from 'react'

const Videos = () => {
    // animation
    const animationElements = useRef([]);

    const checkIfInView = () => {
        const windowHeight = window.innerHeight;
        const windowTopPosition = window.scrollY;
        const windowBottomPosition = windowTopPosition + windowHeight;

        animationElements.current.forEach(element => {
            const elementHeight = element.offsetHeight;
            const elementTopPosition = element.offsetTop;
            const elementBottomPosition = elementTopPosition + elementHeight;

            if (
                elementBottomPosition >= windowTopPosition &&
                elementTopPosition <= windowBottomPosition
            ) {
                element.classList.add('in-view');
            } else {
                element.classList.remove('in-view');
            }
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', checkIfInView);
        window.addEventListener('resize', checkIfInView);
        checkIfInView(); // Initial check

        return () => {
            window.removeEventListener('scroll', checkIfInView);
            window.removeEventListener('resize', checkIfInView);
        };
    }, []);
    return (
        <>
            <section className='breakcrumb'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xl-12'>
                            <h1 className='roboto-medium text-white'>Videos</h1>
                            <h5 className='text-white'>(Directorate Of Rural Sanitation, Department of Rural Development & Panchayati Raj ,J&K)</h5>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='container mt-5 animation-element slide-up testimonial' ref={(el) => {
                    if (el && !animationElements.current.includes(el)) {
                        animationElements.current.push(el);
                    }
                }}>
                    <div className="row">
                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/vwLEHzhpUYU?si=YdmAgkyhexdX_t1m" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                              
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/v-6MnBXg-io?si=6qnkgNVrzV9IL-bD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/7znLmlq5MLs?si=FFl594DBaQP5oWKZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/w41R-NXRlaE?si=-dd_WmcSPnZvIpXm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/bpdy5pjRwVo?si=9MhHDQFtCRUt2Hpm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/E3lBShjpeb0?si=crmt5NCZLG2oXP77" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/kJ8NIu9plXc?si=M5nYbthunPaSLjBI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/yXo6rk9TsT8?si=OiESVg_QpPYRfIw4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/yHsSMkcvxkQ?si=gmXTmm7vJ2HB6iht" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/C086o9RqeYA?si=haWAtbVrRKlj-8NL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/WCD07GS4VWo?si=C025H6wx3cvEy2na" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/ebwaV8i4QbA?si=jgimMGjogt0m-58a" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/CXuKmhREuSM?si=mKe4begW5zUoKRpv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/4QnN0FL8ZzI?si=H3wDwkQT6Tk4RvR3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/-fkLyM3uqqk?si=qJX5Hbx5T54m1dnP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/Rbp_Keo5TDg?si=CrP7Ywztltx6H-jZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Videos

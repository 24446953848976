

import axios from "axios";

const apiService = axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
});

const admin_get_data = (endpoint,loggedData) => {
    //console.log(`process.env.REACT_APP_API_BASE_URL`);
    return apiService.get(endpoint, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${loggedData.token}`,
        },
    });
};
const admin_post_data = (endpoint, data, loggedData) => {
    console.log("LoggedData:",loggedData);
    return apiService.post(endpoint, data, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${loggedData.token}`,
        },
    });
};
const admin_post_update_data = (endpoint, data, loggedData) => {
    console.log("LoggedData:",loggedData);
    return apiService.put(endpoint, data, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${loggedData.token}`,
        },
    });
};
const admin_post_UpdateformData = (endpoint, formData, loggedData) => {
    return apiService.put(endpoint, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${loggedData.token}`,
        },
    });
};
const admin_post_formData = (endpoint, formData, loggedData) => {
    return apiService.post(endpoint, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${loggedData.token}`,
        },
    });
};

const user_get_data = (endpoint) => {
    return apiService.get(endpoint, {
        headers: {
            "Content-Type": "application/json",
        },
    });
};
const user_post_data = (endpoint, data) => {
    return apiService.post(endpoint, data, {
        headers: {
            "Content-Type": "application/json",
        },
    });
};
const admin_delete_data = (endpoint) => {
    const token = getAccessToken();
    return apiService.post(endpoint, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

const user_post_formData = (endpoint, formData) => {
    return apiService.post(endpoint, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};
const getAccessToken = () => {
    return localStorage.getItem('access_token');
};

const user_post_imgs= (endpoint, formData,loggedData) => {
    return apiService.post(endpoint, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${loggedData.token}`,
        },
    });
};

export {
    admin_get_data,
    admin_post_data,
    admin_post_formData,
    user_get_data,
    user_post_data,
    user_post_formData,
    user_post_imgs,
    admin_delete_data,
    admin_post_update_data,
    admin_post_UpdateformData
};



// feedback
// const user_post_data = (endpoint, data) => {
//     return apiService.post(endpoint, data, {
//         headers: {
//             "Content-Type": "application/json",
//         },
//     });
// };
import React, { useRef, useEffect } from 'react'
import map from '../assets/IMAGES/route-map.png'
import Yatra2 from '../assets/IMAGES/yatra-2.png'


const Yatra = () => {
  // animation
  const animationElements = useRef([]);

  const checkIfInView = () => {
    const windowHeight = window.innerHeight;
    const windowTopPosition = window.scrollY;
    const windowBottomPosition = windowTopPosition + windowHeight;

    animationElements.current.forEach(element => {
      const elementHeight = element.offsetHeight;
      const elementTopPosition = element.offsetTop;
      const elementBottomPosition = elementTopPosition + elementHeight;

      if (
        elementBottomPosition >= windowTopPosition &&
        elementTopPosition <= windowBottomPosition
      ) {
        element.classList.add('in-view');
      } else {
        element.classList.remove('in-view');
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', checkIfInView);
    window.addEventListener('resize', checkIfInView);
    checkIfInView(); // Initial check

    return () => {
      window.removeEventListener('scroll', checkIfInView);
      window.removeEventListener('resize', checkIfInView);
    };
  }, []);
  return (
    <>
      <section className='breakcrumb'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-12'>
              <h1 className='roboto-medium text-white'>Shir Amarnath Ji Yatra 2024</h1>
              <h5 className='text-white'>(Directorate Of Rural Sanitation, Department of Rural Development & Panchayati Raj ,J&K)</h5>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container mt-5 animation-element slide-up testimonial' ref={(el) => {
          if (el && !animationElements.current.includes(el)) {
            animationElements.current.push(el);
          }
        }}>
          <div className='row align-items-center mb-5'>
            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
              <h3 className='roboto-medium color-blue'>JAMMU - PAHALGAM - HOLY CAVE</h3>
              <p className='roboto-medium'>Pahalgam is a popular hill station located in the Anantnag district of Jammu and Kashmir, India. It is also a base camp for the annual Amarnath Yatra.</p>
              <h5 className='roboto-medium'> Distance:</h5>
              <p className='roboto-medium'>The distance from Jammu to Pahalgam is approximately 285 kilometers (177 miles).</p>
              <h5 className='roboto-medium'>Travel Options:</h5>
              <p className='roboto-medium'><b>By Road:</b></p>
              <div className='do mt-2'>
                <ul className='ps-0'>
                  <li>Bus: Regular buses operate from Jammu to Pahalgam. The journey by bus can take around 8 to 10 hours</li>
                  <li>Taxi/Private Car : Hiring a taxi or driving a private car is another option. The travel time by car is typically around 7 to 8 hours, depending on road conditions.</li>
                </ul>
              </div>
              <p className='roboto-medium'><b>By Train:</b></p>
              <div className='do mt-2'>
                <ul className='ps-0'>
                  <li>There are no direct trains to Pahalgam. The nearest railway station is Jammu Tawi. From Jammu Tawi, you can take a taxi or bus to Pahalgam.</li>
                </ul>
              </div>
              <p className='roboto-medium'><b>By Air:</b></p>
              <div className='do mt-2'>
                <ul className='ps-0'>
                  <li>The nearest airport is in Srinagar. From Srinagar, Pahalgam is about 95 kilometers (59 miles) away, and you can reach Pahalgam by taxi or bus.</li>
                </ul>
              </div>
              </div>
              <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
              <img src={map} className='w-100' alt="" />
            </div>
            </div>
              <div className='row'>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                <h3 className='roboto-medium color-blue mt-3'>JAMMU - BALTAL - HOLY CAVE</h3>
              <h5 className='roboto-medium'>Travel to Jammu:</h5>
              <p className='roboto-medium'><b>By Air:</b>Jammu has an airport (Satwari Airport), with regular flights from major cities like Delhi, Mumbai, and Srinagar.
              </p>
              <p className='roboto-medium'><b>By Train:</b> Jammu Tawi is the main railway station, well-connected to major cities across India.
              </p>
              <p className='roboto-medium'><b>By Road:</b> Jammu is connected via national highways. Buses and taxis are available from nearby states and cities.
              </p>
              <h5 className='roboto-medium'>Jammu to Baltal</h5>
              <p className='roboto-medium'>By Road:
              </p>
              <div className='do mt-2'>
              <p className='roboto-medium'><b>Jammu to Srinagar:
              </b>
              </p>
                <ul className='ps-0'>
                  <li><b>Distance :</b> ~260 kilometers.
                  </li>
                  <li><b>Duration :</b> 7-8 hours by bus/taxi.
                  </li>
                  <li><b>Route :</b> Jammu – Udhampur – Patnitop – Banihal – Qazigund – Anantnag – Srinagar.
                  </li>
                </ul>
              </div>
              <div className='do mt-2'>
              <p className='roboto-medium'><b>Srinagar to Baltal:
              </b>
              </p>
                <ul className='ps-0'>
                  <li><b>Distance :</b>  ~93 kilometers.              
                  </li>
                  <li><b>Duration :</b> 3-4 hours by taxi.
                  </li>
                  <li><b>Route :</b> Srinagar – Ganderbal – Sonamarg – Baltal.
                  </li>
                </ul>
              </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
              <img src={Yatra2} className='w-100' alt="" />
            </div>
              </div>
             
           
          
         
        </div>
      </section>

    
    </>
  )
}

export default Yatra

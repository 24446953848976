import React, {useEffect,useRef,useState}from 'react'
import image1 from '../assets/IMAGES/sended/send-5.jpeg'
import image2 from '../assets/IMAGES/sended/send-6.jpeg'
import image3 from '../assets/IMAGES/sended/send-7.jpeg'
import image4 from '../assets/IMAGES/sended/send-5.jpeg'
import image5 from '../assets/IMAGES/sended/send-6.jpeg'
import image6 from '../assets/IMAGES/sended/send-7.jpeg'
import image7 from '../assets/IMAGES/sended/send-8.jpeg'
import image8 from '../assets/IMAGES/sended/send-9.jpeg'
import image9 from '../assets/IMAGES/sended/send-11.jpeg'
import image10 from '../assets/IMAGES/sended/send-12.jpeg'
import image11 from '../assets/IMAGES/sended/send-13.jpeg'
import image12 from '../assets/IMAGES/sended/send-14.jpeg'
import image13 from '../assets/IMAGES/sended/send-15.jpeg'
import image14 from '../assets/IMAGES/sended/send-16.jpeg'
import image15 from '../assets/IMAGES/sended/send-17.jpeg'
import image16 from '../assets/IMAGES/sended/send-18.jpeg'
import image17 from '../assets/IMAGES/sended/send-19.jpeg'
import image18 from '../assets/IMAGES/sended/send-20.jpeg'
import image19 from '../assets/IMAGES/sended/send-21.jpeg'
import image20 from '../assets/IMAGES/sended/send-22.jpeg'
import image21 from '../assets/IMAGES/sended/send-23.jpeg'
import image22 from '../assets/IMAGES/sended/send-24.jpeg'

const Gallery = () => {
    // animation
    const animationElements = useRef([]);

    const checkIfInView = () => {
      const windowHeight = window.innerHeight;
      const windowTopPosition = window.scrollY;
      const windowBottomPosition = windowTopPosition + windowHeight;
  
      animationElements.current.forEach(element => {
        const elementHeight = element.offsetHeight;
        const elementTopPosition = element.offsetTop;
        const elementBottomPosition = elementTopPosition + elementHeight;
  
        if (
          elementBottomPosition >= windowTopPosition &&
          elementTopPosition <= windowBottomPosition
        ) {
          element.classList.add('in-view');
        } else {
          element.classList.remove('in-view');
        }
      });
    };
  
    useEffect(() => {
      window.addEventListener('scroll', checkIfInView);
      window.addEventListener('resize', checkIfInView);
      checkIfInView(); // Initial check
  
      return () => {
        window.removeEventListener('scroll', checkIfInView);
        window.removeEventListener('resize', checkIfInView);
      };
    }, []);
    // gallery
    const [modalOpen, setModalOpen] = useState(false);
  const [slideIndex, setSlideIndex] = useState(1);

  const slides = [
    { src: image1, alt: 'Image 1' },
    { src:  image2, alt: 'Image 2' },
    { src:  image3, alt: 'Image 3' },
    { src: image4, alt: 'Image 4' },
    { src: image5, alt: 'Image 5' },
    { src: image6, alt: 'Image 6' },
    { src: image7, alt: 'Image 7' },
    { src: image8, alt: 'Image 8' },
    // Add more slides as needed
  ];

  const openModal = (index) => {
    setSlideIndex(index);
    setModalOpen(true);
  };

  const closeModal = () => setModalOpen(false);

  const plusSlides = (n) => setSlideIndex(prev => (prev + n) > slides.length ? 1 : (prev + n) < 1 ? slides.length : prev + n);
  const currentSlide = (n) => setSlideIndex(n);

    return (
      <>
      <section className='breakcrumb'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-12'>
            <h1 className='roboto-medium text-white'>Gallery</h1>
            <h5 className='text-white'>(Directorate Of Rural Sanitation, Department of Rural Development & Panchayati Raj ,J&K)</h5>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='container mt-5 animation-element slide-up testimonial' ref={(el) => {
          if (el && !animationElements.current.includes(el)) {
            animationElements.current.push(el);
          }
        }}>
         <div className="row">
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                <div className='card'>
                    <img src={image1} className='w-100 height-250' alt="" />
                </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                <div className='card'>
                    <img src={image2} className='w-100 height-250' alt="" />
                </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                <div className='card'>
                    <img src={image3} className='w-100 height-250' alt="" />
                </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                <div className='card'>
                    <img src={image4} className='w-100 height-250' alt="" />
                </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                <div className='card'>
                    <img src={image5} className='w-100 height-250' alt="" />
                </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                <div className='card'>
                    <img src={image6} className='w-100 height-250' alt="" />
                </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                <div className='card'>
                    <img src={image7} className='w-100 height-250' alt="" />
                </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                <div className='card'>
                    <img src={image8} className='w-100 height-250' alt="" />
                </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                <div className='card'>
                    <img src={image9} className='w-100 height-250' alt="" />
                </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                <div className='card'>
                    <img src={image10} className='w-100 height-250' alt="" />
                </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                <div className='card'>
                    <img src={image11} className='w-100 height-250' alt="" />
                </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                <div className='card'>
                    <img src={image12} className='w-100 height-250' alt="" />
                </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                <div className='card'>
                    <img src={image13} className='w-100 height-250' alt="" />
                </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                <div className='card'>
                    <img src={image14} className='w-100 height-250' alt="" />
                </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                <div className='card'>
                    <img src={image15} className='w-100 height-250' alt="" />
                </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                <div className='card'>
                    <img src={image16} className='w-100 height-250' alt="" />
                </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                <div className='card'>
                    <img src={image17} className='w-100 height-250' alt="" />
                </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                <div className='card'>
                    <img src={image18} className='w-100 height-250' alt="" />
                </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                <div className='card'>
                    <img src={image19} className='w-100 height-250' alt="" />
                </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                <div className='card'>
                    <img src={image20} className='w-100 height-250' alt="" />
                </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                <div className='card'>
                    <img src={image21} className='w-100 height-250' alt="" />
                </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4'>
                <div className='card'>
                    <img src={image22} className='w-100 height-250' alt="" />
                </div>
            </div>
            </div>
            </div>
            </section>
      {/* <section>
        <div className='container mt-5 animation-element slide-up testimonial' ref={(el) => {
          if (el && !animationElements.current.includes(el)) {
            animationElements.current.push(el);
          }
        }}>
         <div className="row">
        {slides.map((slide, index) => (
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-4" key={index}>
            <img
              src={slide.src}
              className="w-100 height-250"
              alt={slide.alt}
              style={{ width: '100%' }}
              onClick={() => openModal(index + 1)}
            />
          </div>
        ))}
      </div>
      </div>

      {modalOpen && (
        <div id="myModal" className="modal cultural_modal ">
          <span className="close cursor" onClick={closeModal}>&times;</span>
          <div className="modal-content modal-dialog-centered modal-lg">
            {slides.map((slide, index) => (
              <div className={`mySlides ${index + 1 === slideIndex ? 'active' : ''}`} key={index}>
                <div className="numbertext">{index + 1} / {slides.length}</div>
                <img src={slide.src} style={{ width: '100%',height :'400px' }} alt={slide.alt} />
              </div>
            ))}
            <a className="prev" onClick={() => plusSlides(-1)}>&#10094;</a>
            <a className="next" onClick={() => plusSlides(1)}>&#10095;</a>
          </div>
        </div>
      )}
    </section> */}
    </>
  )
}


export default Gallery

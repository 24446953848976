import React, {useEffect} from 'react'

const FrontFooter = () => {
  const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
};

useEffect(() => {
    const mybutton = document.getElementById("myBtn");
    // When the user scrolls down 20px from the top of the document, show the button
    const scrollFunction = () => {
        if (mybutton) {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                mybutton.style.display = "block";
            } else {
                mybutton.style.display = "none";
            }
        }
    };
    window.onscroll = function () {
        scrollFunction();
    };

    // Clean up the event listener when the component unmounts
    return () => {
        window.onscroll = null;
    };
}, []);
  return (
    <>
        {/* section footer start*/}
        <section className='footer'>
            <div className='container'>
              <div className='row d-flex justify-content-center'>
                <div className='col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'>
                <p className='roboto-bold text-center'>
                  Jammu and Kashmir State Centre.<br></br>
                All the Contents provided, maintain by Directorate Of Rural Sanitation,<br></br> Department of Rural Development & Panchayati Raj ,J&K.
                </p>
              </div>
              </div>
              <div className='border-top-1px'></div>
              <div class="h3fb-social">
              <ul>
              <li>
              <a href="https://www.facebook.com/profile.php?id=61561165573879" target="_blank">
              <i class="fab fa-facebook-f facebook"></i>
              </a>
              </li>

              <li><a href="https://youtu.be/hrtMxWuxuOM?si=1_iMerLKisiCEP4c" target="_blank"><i class="fab fa-youtube youtube"></i></a></li>
              <li><a  target="_blank"><i class="fab fa-instagram instragram"></i></a></li>
              </ul>
              </div>
              
            </div>
            {/* <div  id="myBtn">
            <i class="fas fa-arrow-up"  onClick={topFunction}></i>
            </div> */}

        <div id='myBtn' onClick={topFunction}>
        <div class="active circle--footer" >
                  <i  className="fas fa-arrow-up" ></i>
                </div>
        </div >
           
           </section>
           {/* section footer end*/}

    
    </>
  )
}

export default FrontFooter
